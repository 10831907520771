/*@tailwind preflight;*/

.rounded-8 {
  border-radius: .8rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.border-0 {
  border-width: 0
}

.border {
  border-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-box;
  display: flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
          flex-direction: column
}

.items-start {
  -webkit-box-align: start;
          align-items: flex-start
}

.items-center {
  -webkit-box-align: center;
          align-items: center
}

.justify-end {
  -webkit-box-pack: end;
          justify-content: flex-end
}

.justify-center {
  -webkit-box-pack: center;
          justify-content: center
}

.justify-between {
  -webkit-box-pack: justify;
          justify-content: space-between
}

.flex-1 {
  -webkit-box-flex: 1;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-box-flex: 1;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-box-flex: 0;
          flex-grow: 0
}

.flex-shrink-0 {
  flex-shrink: 0
}

.flex-shrink {
  flex-shrink: 1
}

.font-600 {
  font-weight: 600
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-full {
  height: 100%
}

.m-8 {
  margin: 0.8rem
}

.m-16 {
  margin: 1.6rem
}

.m-24 {
  margin: 2.4rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.mt-0 {
  margin-top: 0
}

.mr-4 {
  margin-right: 0.4rem
}

.mb-4 {
  margin-bottom: 0.4rem
}

.ml-4 {
  margin-left: 0.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mr-8 {
  margin-right: 0.8rem
}

.ml-8 {
  margin-left: 0.8rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mr-16 {
  margin-right: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.-ml-12 {
  margin-left: -1.2rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-64 {
  min-width: 6.4rem
}

.opacity-50 {
  opacity: 0.5
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.pl-0 {
  padding-left: 0
}

.pt-8 {
  padding-top: 0.8rem
}

.pr-8 {
  padding-right: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pl-8 {
  padding-left: 0.8rem
}

.pr-12 {
  padding-right: 1.2rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pr-16 {
  padding-right: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pl-20 {
  padding-left: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pl-40 {
  padding-left: 4rem
}

.pr-48 {
  padding-right: 4.8rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pb-80 {
  padding-bottom: 8rem
}

.pl-80 {
  padding-left: 8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

.text-center {
  text-align: center
}

.text-white {
  color: #FFFFFF
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-20 {
  font-size: 2rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-256 {
  width: 25.6rem
}

.w-xs {
  width: 32rem
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

@media (min-width: 600px) {

  .sm\:rounded-16 {
    border-radius: 1.6rem
  }

  .sm\:flex {
    display: -webkit-box;
    display: flex
  }

  .sm\:mb-0 {
    margin-bottom: 0
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:pb-80 {
    padding-bottom: 8rem
  }

  .sm\:w-224 {
    width: 22.4rem
  }

  .sm\:w-256 {
    width: 25.6rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-box;
    display: flex
  }

  .md\:flex-row {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-box-align: start;
            align-items: flex-start
  }

  .md\:flex-1 {
    -webkit-box-flex: 1;
            flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    flex-shrink: 0
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  .lg\:pl-0 {
    padding-left: 0
  }
}
